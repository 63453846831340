import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
// import BackgroundSlideshow from 'react-background-slideshow';
import { Row, Col, Modal } from 'antd';
import { buyerSideMenu } from '../../layout/consts';
import { can } from '../../common/utils';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = { visible: false };
  }

  toggleContact() {
    this.setState({ visible: !this.state.visible });
  }

  checkInitialUrl(sideMenu, currentUser) {
    let path = '';

    for (const menu of sideMenu) {
      if (menu.subMenu) {
        for (const j of menu.subMenu) {
          if (can(j.permission, currentUser)) {
            path = j.url;
            break;
          }
        }
      } else if (can(menu.permission, currentUser)) {
        path = menu.url;
        break;
      }
    }
    return path;
  }

  render() {
    const { currentUser, __, locale, toggleLang } = this.context;

    const path = this.checkInitialUrl(buyerSideMenu, currentUser);

    if (
      currentUser &&
      currentUser.userPermission &&
      (!currentUser.userPermission[0] && currentUser.role !== 'admin')
    ) {
      return null;
    }

    if (currentUser) {
      currentUser.isSupplier
        ? this.props.history.push('/rfq-and-eoi')
        : this.props.history.push(path);
    }

    // const images = [];

    // for (let i = 1; i < 2; i++) {
    //   images.push(`${process.env.PUBLIC_URL}/images/slider/image${i}.jpg`);
    // }

    return (
      <div>
        {/* <BackgroundSlideshow images={images} /> */}
        <img className="home-landing" src={`${process.env.PUBLIC_URL}/images/slider/image1.jpg`} />
        <div className="home-landing">
          <div className="home-container">
            <div className="top bg-white">
              <Row style={{ marginBottom: '0px' }}>
                <Col span={6}>
                  <img
                    className="pull-left"
                    src={`${process.env.PUBLIC_URL}/images/logo.jpg`}
                    height="120"
                    alt="logo"
                  />
                </Col>
                <Col span={12}>
                  <p className="text">
                    {__(
                      'Together deliver a safe and globally competitive copper business that contributes to the prosperity of Mongolia'
                    )}
                  </p>
                </Col>
                <Col span={6}>
                  <img
                    className="pull-right"
                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                    width="85"
                    alt="logo"
                  />
                </Col>
                <ul className="pull-right" id="LoginMenus">
                  <li>
                    <Link to="/sign-in">{__('Sign in')}</Link>
                  </li>
                  <li>
                    <Link to="/register">{__('Register as a supplier')}</Link>
                  </li>
                  <li>
                    <a href="#contact-us" onClick={() => this.toggleContact()}>
                      {__('Contact us')}
                    </a>
                    <Modal
                      title={__('Contact us')}
                      visible={this.state.visible}
                      footer={null}
                      onCancel={() => this.toggleContact()}
                    >
                      <Row gutter={20}>
                        <Col span={24}>
                          <div className="footer">
                            <Row gutter={20}>
                              <Col span={24}>
                                <p>
                                  {__(
                                    'If you have any questions about registering in the Oyu database, please contact the following address:'
                                  )}
                                </p>
                                <b>
                                  {__('Oyu supplier database system:')}
                                  <br />
                                  <br />
                                </b>
                              </Col>
                              <Col span={16}>
                                <p>
                                  <b>{__('Email')}:</b> OTOyuSupport@ot.mn
                                  <br />
                                  <b>{__('Phone')}:</b> +(976) – (11) -331880, {__('Ext')} 8888
                                  <br />
                                  <b>{__('Fax')}:</b> +(976) – (11) -331890
                                  <br />
                                </p>
                              </Col>
                              <Col span={24}>
                                <p>
                                  {__(
                                    'Oyu Tolgoi LLC Monnis tower 5th floor Chinggis Avenue 15 Sukhbaatar District – 14240 Ulaanbaatar, Mongolia'
                                  )}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Modal>
                  </li>
                  <li>
                    <a href="#lang" onClick={toggleLang}>
                      {locale === 'en' ? 'MN' : 'EN'}
                    </a>
                  </li>
                </ul>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  history: PropTypes.object,
};

Home.contextTypes = {
  currentUser: PropTypes.object,
  __: PropTypes.func,
  toggleLang: PropTypes.func,
  locale: PropTypes.string,
};

export default withRouter(Home);
